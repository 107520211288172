import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { TestState } from './state';

const defaultState: TestState = {
  tests: [],
  tests_count: 0,
  page: 1,
  perPage: 10,
  sortBy: 'created_at',
  sortDesc: false,
  testSummary: '',
  traces: [],
  assays: [],
  archiveTasks: {},
};

export const testsModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
