import Router from 'vue-router';

import RouterComponent from '@/components/RouterComponent.vue';

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      meta: { breadCrumb: 'Home' },
      component: () => import(/* webpackChunkName: "start" */ '@/views/main/Start.vue'),
      children: [
        {
          path: 'login',
          meta: { breadCrumb: 'Login' },
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
        },
        {
          path: 'recover-password',
          component: () => import(/* webpackChunkName: "recover-password" */ '@/views/PasswordRecovery.vue'),
        },
        {
          path: 'reset-password',
          component: () => import(/* webpackChunkName: "reset-password" */ '@/views/ResetPassword.vue'),
        },
        {
          path: 'main',
          meta: { breadCrumb: 'Main' },
          component: () => import(/* webpackChunkName: "main" */ '@/views/main/Main.vue'),
          children: [
            {
              path: 'dashboard',
              meta: { breadCrumb: 'Dashboard' },
              component: () => import(/* webpackChunkName: "main-dashboard" */ '@/views/main/Dashboard.vue'),
            },
            {
              path: 'files',
              meta: { breadCrumb: 'My Files' },
              component: () => import(/* webpackChunkName: "main-files" */ '@/views/main/Files.vue'),
            },
            {
              path: 'assays/:id',
              name: 'main-assay-view',
              meta: { breadCrumb: 'View Assay' },
              component: () => import(/* webpackChunkName: "main-assay-view" */ '@/views/main/Assay.vue'),
            },
            {
              path: 'tests',
              meta: { breadCrumb: 'Tests' },
              component: RouterComponent,
              redirect: 'tests/all',
              children: [
                {
                  path: 'all',
                  meta: { breadCrumb: 'All Tests' },
                  component: () => import(/* webpackChunkName: "tests" */ '@/views/main/Tests.vue'),
                },
                {
                  path: ':id',
                  name: 'main-test-view',
                  meta: { breadCrumb: 'View Test' },
                  component: () => import(/* webpackChunkName: "test" */ '@/views/main/Test.vue'),
                },
              ],
            },
            {
              path: 'profile',
              meta: { breadCrumb: 'Profile' },
              component: RouterComponent,
              redirect: 'profile/view',
              children: [
                {
                  path: 'view',
                  component: () => import(
                    /* webpackChunkName: "main-profile" */ '@/views/main/profile/UserProfile.vue'),
                },
                {
                  path: 'edit',
                  component: () => import(
                    /* webpackChunkName: "main-profile-edit" */ '@/views/main/profile/UserProfileEdit.vue'),
                },
                {
                  path: 'password',
                  component: () => import(
                    /* webpackChunkName: "main-profile-password" */ '@/views/main/profile/UserProfileEditPassword.vue'),
                },
              ],
            },
            {
              path: 'admin',
              meta: { breadCrumb: 'Admin' },
              component: () => import(/* webpackChunkName: "main-admin" */ '@/views/main/admin/Admin.vue'),
              redirect: 'admin/users/all',
              children: [
                {
                  path: 'users',
                  meta: { breadCrumb: 'Users' },
                  component: () => import(
                    /* webpackChunkName: "main-admin-users" */ '@/views/main/admin/AdminUsers.vue'),
                },
                {
                  path: 'users/edit/:id',
                  name: 'main-admin-users-edit',
                  meta: { breadCrumb: 'Edit User' },
                  component: () => import(
                    /* webpackChunkName: "main-admin-users-edit" */ '@/views/main/admin/EditUser.vue'),
                },
                {
                  path: 'users/create',
                  meta: { breadCrumb: 'Create User' },
                  name: 'main-admin-users-create',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users-create" */ '@/views/main/admin/CreateUser.vue'),
                },
                {
                  path: 'systems',
                  meta: { breadCrumb: 'Systems' },
                  component: () => import(
                    /* webpackChunkName: "main-admin-systems" */ '@/views/main/admin/AdminSystems.vue'),
                },
                {
                  path: 'systems/:id',
                  name: 'main-admin-system-view',
                  meta: { breadCrumb: 'View System' },
                  component: () => import(
                    /* webpackChunkName: "main-admin-system-view" */ '@/views/main/admin/AdminViewSystem.vue'),
                },
                {
                  path: 'releases',
                  meta: { breadCrumb: 'Releases' },
                  component: () => import(
                    /* webpackChunkName: "main-admin-releases" */ '@/views/main/admin/AdminReleases.vue'),
                },
                // {
                //   path: 'releases/edit/:id',
                //   name: 'main-admin-releases-edit',
                //   component: () => import(
                //     /* webpackChunkName: "main-admin-releases-edit" */ '@/views/main/admin/EditRelease.vue'),
                // },
                // {
                //   path: 'releases/create',
                //   name: 'main-admin-releases-create',
                //   component: () => import(
                //     /* webpackChunkName: "main-admin-releases-create" */ '@/views/main/admin/CreateRelease.vue'),
                // },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/*', redirect: '/',
    },
  ],
});
