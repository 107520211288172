import { IUserProfile, ISystem, IRelease } from '@/interfaces';
import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setUsers(state: AdminState, payload: IUserProfile[]) {
        state.users = payload;
    },
    setUser(state: AdminState, payload: IUserProfile) {
        const users = state.users.filter((user: IUserProfile) => user.id !== payload.id);
        users.push(payload);
        state.users = users;
    },
    setSystems(state: AdminState, payload: ISystem[]) {
        state.systems = payload;
    },
    setSystem(state: AdminState, payload: ISystem) {
        const systems = state.systems.filter((system: ISystem) => system.id !== payload.id);
        systems.push(payload);
        state.systems = systems;
    },
    setReleases(state: AdminState, payload: IRelease[]) {
        state.releases = payload;
    },
    setRelease(state: AdminState, payload: IRelease) {
        const releases = state.releases.filter((release: IRelease) => release.id !== payload.id);
        releases.push(payload);
        state.releases = releases;
    },
};

const { commit } = getStoreAccessors<AdminState, State>('');

export const commitSetUser = commit(mutations.setUser);
export const commitSetUsers = commit(mutations.setUsers);

export const commitSetSystem = commit(mutations.setSystem);
export const commitSetSystems = commit(mutations.setSystems);

export const commitSetRelease = commit(mutations.setRelease);
export const commitSetReleases = commit(mutations.setReleases);
