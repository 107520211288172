import Vue from 'vue';

import VueSocketIOExt from 'vue-socket.io-extended';
import { io } from 'socket.io-client';

import { apiUrl } from '@/env';

const socket = io(`${apiUrl}`, {
  path: '/api/socket.io/', transports: ['websocket', 'polling'],
});

Vue.use(VueSocketIOExt, socket);
