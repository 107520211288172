import Vue from 'vue';
import VeeValidate from 'vee-validate';

Vue.use(VeeValidate);

// 3.x
// import { ValidationProvider } from 'vee-validate';
// Vue.component('ValidationProvider', ValidationProvider);


