import { TestState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    tests: (state: TestState) => state.tests,
    test: (state: TestState) => (testId: number) => {
        const filteredTests = state.tests.filter((test) => test.id === testId);
        if (filteredTests.length > 0) {
            return { ...filteredTests[0] };
        }
    },
    tests_count: (state: TestState) => state.tests_count,
    page: (state: TestState) => state.page,
    perPage: (state: TestState) => state.perPage,
    sortBy: (state: TestState) => state.sortBy,
    sortDesc: (state: TestState) => state.sortDesc,
    options: (state: TestState) => (
        {page: state.page,
         perPage: state.perPage,
         sortBy: state.sortBy,
         sortDesc: state.sortDesc}
    ),

    testSummary: (state: TestState) => state.testSummary,
    traces: (state: TestState) => state.traces,
    assays: (state: TestState) => state.assays,
    assay: (state: TestState) => (assayId: number) => {
        const filteredAssays = state.assays.filter((assay) => assay.id === assayId);
        if (filteredAssays.length > 0) {
            return { ...filteredAssays[0] };
        }
    },
};

const { read } = getStoreAccessors<TestState, State>('');

export const readTest = read(getters.test);
export const readTests = read(getters.tests);

export const readTestsCount = read(getters.tests_count);
export const readTestsPage = read(getters.page);
export const readTestsPerPage = read(getters.perPage);
export const readTestsSortBy = read(getters.sortBy);
export const readTestsOptions = read(getters.options);

export const readTestSummary = read(getters.testSummary);
export const readTraces = read(getters.traces);

export const readAssay = read(getters.assay);
export const readAssays = read(getters.assays);

