import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    adminUsers: (state: AdminState) => state.users,
    adminOneUser: (state: AdminState) => (userId: number) => {
        const filteredUsers = state.users.filter((user) => user.id === userId);
        if (filteredUsers.length > 0) {
            return { ...filteredUsers[0] };
        }
    },
    adminSystems: (state: AdminState) => state.systems,
    adminOneSystem: (state: AdminState) => (systemId: number) => {
        const filteredUSystems = state.systems.filter((system) => system.id === systemId);
        if (filteredUSystems.length > 0) {
            return { ...filteredUSystems[0] };
        }
    },
    adminReleases: (state: AdminState) => state.releases,
};

const { read } = getStoreAccessors<AdminState, State>('');

export const readAdminOneUser = read(getters.adminOneUser);
export const readAdminUsers = read(getters.adminUsers);
export const readAdminSystems = read(getters.adminSystems);
export const readAdminOneSystem = read(getters.adminOneSystem);
export const readAdminReleases = read(getters.adminReleases);
