import axios from 'axios';
import { apiUrl } from '@/env';
import { IUserProfile, IUserProfileUpdate, IUserProfileCreate,
         ITestResult,
         ISystem, ISystemUpdate,
         IRelease, IReleaseUpdate, IReleaseCreate,
         IAssay, IAnalyte, ITrace, IAutocompleteItem, IFilter,
         IFile, IXIBCSample, IAnalyteValue, IAnnotation, ITest,
} from '@/interfaces';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

function getAnalyteValueByName(analyteValues, name) {
  const val = analyteValues.filter((av) => av.name === name);
  if (val.length > 0) {
    return val[0].value;
  } else {
    return 0.0;
  }
}

function getAnnotationByName(annotations, name) {
  const val = annotations.filter((a) => a.key.indexOf(name) > -1);
  if (val.length > 0) {
    return val[0].value;
  } else {
    return undefined;
  }
}

export default {
  async getClinicalDataForFile(token: string, fileId: number) {
    // console.log(fileId);
    const data: IXIBCSample[] = [];
    const files = await axios.get<IFile[]>(`${apiUrl}/api/v1/files/${fileId}/children`, authHeaders(token));
    // console.log(files.data);
    for (const file of files.data) {
      if (file.file_type === 'RNA') { continue; }
      const tr = await axios.get<ITestResult>(
        `${apiUrl}/api/v1/test_results/${file.test_result_id}`,
        authHeaders(token),
      );
      const clinicalValues = await axios.get<IAnnotation[]>(
        `${apiUrl}/api/v1/test_results/${file.test_result_id}/clinical_values`,
        authHeaders(token),
      );
      const annotations = await axios.get<IAnnotation[]>(
        `${apiUrl}/api/v1/test_results/${file.test_result_id}/annotations`,
        authHeaders(token),
       );
      const testId = tr.data.test_id || 0;
      const testInfo = await axios.get<ITest>(`${apiUrl}/api/v1/tests/${testId}`, authHeaders(token));
      // console.log(testInfo.data);
      const cts = await axios.get<IAnalyteValue[]>(`${apiUrl}/api/v1/tests/${testId}/cts`, authHeaders(token));
      const sample: IXIBCSample = {
        guid: tr.data.test_guid || '0', // testInfo.data.guid,
        gapdh_ct: getAnalyteValueByName(cts.data, 'GAPDH'),
        gusb_ct: getAnalyteValueByName(cts.data, 'GUSB'),
        tfrc_ct: getAnalyteValueByName(cts.data, 'TFRC'),
        foxm1_ct: getAnalyteValueByName(cts.data, 'FOXM1'),
        pttg1_ct: getAnalyteValueByName(cts.data, 'PTTG1'),
        znf367_ct: getAnalyteValueByName(cts.data, 'ZNF367'),
        ln: parseInt(getAnnotationByName(clinicalValues.data, 'clinicalValue.0'), 10),
        size: parseInt(getAnnotationByName(clinicalValues.data, 'clinicalValue.1'), 10),
        sample_id: tr.data.sample_id,
        patient_id: '1234',
        specimen_type: ' ',
        end_time: tr.data.end_time || '',
        comment: getAnnotationByName(annotations.data, 'comment') || testInfo.data.notes || ' ',
        result: tr.data.result || '',
        test_result_id: tr.data.id || 0,
      };
      data.push(sample);
    }
    return data;
  },
  async updateClinicalFactorValueForTest(token: string, key: string, value: string, testResultId: number) {
    return axios.post(`${apiUrl}/api/v1/annotations/`, {key, value, testResultId}, authHeaders(token));
  },
  async updateAnnotationForTest(token: string, key: string, value: string, testResultId: number) {
    return axios.post(`${apiUrl}/api/v1/annotations/`, {key, value, test_result_id: testResultId}, authHeaders(token));
  },
  async getXIBCReport(token: string, samples: IXIBCSample[]) {
    const { headers } = authHeaders(token);
    return axios.post(`${apiUrl}/report`, samples,
      {responseType: 'blob', headers},
    );
  },
  async getXIBCData(token: string, samples: IXIBCSample[]) {
    const { headers } = authHeaders(token);
    const results: IXIBCSample[] = [];
    for (const sample of samples) {
      if (sample.ln !== undefined && !isNaN(sample.ln) &&
          sample.size !== undefined && !isNaN(sample.size)) {
        const result = await axios.post(`${apiUrl}/insight`, sample, authHeaders(token));
        if (result) {
          results.push(result.data);
        }
      }
    }
    return results;
  },
};
