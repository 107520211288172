import { api } from '@/api';
import { ActionContext } from 'vuex';
import { ITestResult, IFilter } from '@/interfaces';
import { State } from '../state';
import { TestState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import {
    commitSetTests, commitSetTest, commitSetTestsCount, commitSetTestSummary,
    commitSetTraces, commitSetAssays, commitSetArchiveTask,
} from './mutations';
import { readTestsOptions } from './getters';
import { dispatchCheckApiError } from '../main/actions';
import FileSaver from 'file-saver';
// import { commitAddNotification, commitRemoveNotification } from '../main/mutations';

type MainContext = ActionContext<TestState, State>;

export const actions = {
    async actionGetTests(context: MainContext, filters: IFilter[]) {
        try {
            const response = await api.getTests(
                context.rootState.main.token,
                {filters, ...readTestsOptions(context)},
            );
            if (response) {
                commitSetTests(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetTestsCount(context: MainContext, filters: IFilter[]) {
        try {
            const response = await api.getTestsCount(
                context.rootState.main.token,
                filters || [],
            ); // TODO: un-hack this fix
            if (response) {
                commitSetTestsCount(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetTestSummary(context: MainContext, payload:
        {
            tests: number[], format: string, analytes: object[], readings: object[],
            groupBy: string, extraData?: object[], extraAnalytes?: object,
        },
    ) {
        try {
            const response = await api.getTestSummary(context.rootState.main.token, payload);
            if (response) {
                if (payload.format === 'EXCEL') {
                    const fileNameHeader = 'x-suggested-filename';
                    const suggestedFileName = response.headers[fileNameHeader];
                    const effectiveFileName = (suggestedFileName === undefined
                                ? 'GXdb_summary.xlsx'
                                : suggestedFileName);
                    FileSaver.saveAs(response.data, effectiveFileName);
                }
                if (payload.format === 'HTML') {
                    commitSetTestSummary(context, response.data);
                }
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetTraces(context: MainContext, payload: {tests: number[], kind: string}) {
        try {
            const response = await api.getTraces(context.rootState.main.token, payload.tests, payload.kind);
            if (response) {
                commitSetTraces(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetAssays(context: MainContext, payload: number[]) {
        try {
            const response = await api.getAssays(context.rootState.main.token, payload);
            if (response) {
                commitSetAssays(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionMakeArchive(context: MainContext, tests: number[]) {
        try {
            const response = await api.makeArchive(context.rootState.main.token, tests);
            if (response) {
                commitSetArchiveTask(context, {taskId: response.data, testIds: tests});
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    /*async actionGetAnalytes(context: MainContext, payload: int[]) {
        try {
            const response = await api.getAnalytes(context.rootState.main.token, payload);
            if (response) {
                commitSetTraces(context, response.data);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },*/
    /*async actionUpdateTest(context: MainContext, payload: { id: number, user: ITestResultUpdate }) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateUser(context.rootState.main.token, payload.id, payload.user),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetUser(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'User successfully updated', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },*/
    /*async actionCreateUser(context: MainContext, payload: IUserProfileCreate) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createUser(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetUser(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'User successfully created', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },*/
};


const { dispatch } = getStoreAccessors<TestState, State>('');

// export const dispatchCreateUser = dispatch(actions.actionCreateUser);
export const dispatchGetTests = dispatch(actions.actionGetTests);
export const dispatchGetTestsCount = dispatch(actions.actionGetTestsCount);
export const dispatchGetTestSummary = dispatch(actions.actionGetTestSummary);
export const dispatchGetTraces = dispatch(actions.actionGetTraces);
export const dispatchGetAssays = dispatch(actions.actionGetAssays);
export const dispatchMakeArchive = dispatch(actions.actionMakeArchive);
// export const dispatchUpdateTest = dispatch(actions.actionUpdateTest);
