import axios from 'axios';
import { apiUrl } from '@/env';
import { IUserProfile, IUserProfileUpdate, IUserProfileCreate,
         ITestResult,
         ISystem, ISystemUpdate,
         IRelease, IReleaseUpdate, IReleaseCreate,
         IAssay, IAnalyte, ITrace, IAutocompleteItem, IFilter,
         IFile,
} from '@/interfaces';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

import testsApi from './tests';


import * as msal from '@azure/msal-browser';

const msalConfig = {
    auth: {
        clientId: 'c30f2d78-8d53-403f-8362-02a39ca8533f', //'54419687-ac05-4e7b-aa32-5f423f0423b5',
        authority: 'https://login.microsoftonline.com/common/',
        //redirectUri: 'http://localhost:8080/auth',
    },
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

export default {
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },

  async loginMsal() {
    /*try {
      const loginResponse = await msalInstance.loginPopup({} as msal.PopupRequest);
      console.log(loginResponse);
      return loginResponse;
    } catch (err) {
        // handle error
        console.log('error in MSAL');
    }*/
    try {
        const loginResponse = await msalInstance.ssoSilent({} as msal.PopupRequest);
        console.log(loginResponse);
        return loginResponse;
    } catch (err) {
      if (err instanceof msal.InteractionRequiredAuthError) {
        const loginResponse = await msalInstance.loginPopup({} as msal.PopupRequest).catch(error => {
            console.log(`error in interactive MSAL ${error}`);
        });
        console.log(loginResponse);
        return loginResponse;
      } else {
          console.log(`error in silent MSAL ${err}`);
      }
    }

  },

  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`, authHeaders(token));
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(`${apiUrl}/api/v1/users/me`, data, authHeaders(token));
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/`, authHeaders(token));
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/users/${userId}`, data, authHeaders(token));
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/users/`, data, authHeaders(token));
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/v1/reset-password/`, {
      new_password: password,
      token,
    });
  },
  ...testsApi,
};


