import axios from 'axios';
import { apiUrl } from '@/env';
import { IUserProfile, IUserProfileUpdate, IUserProfileCreate,
         ITestResult, ITest,
         ISystem, ISystemUpdate,
         IRelease, IReleaseUpdate, IReleaseCreate,
         IAssay, IAnalyte, ITrace, IAutocompleteItem, IFilter,
         IFile, ICommandSequence, IAnalyteValue,
} from '@/interfaces';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export default {
  async getAssay(token: string, assayId: number) {
    return axios.get<IAssay>(`${apiUrl}/api/v1/assays/${assayId}`, authHeaders(token));
  },
  async getTest(token: string, testId: number) {
    return axios.get<ITest>(`${apiUrl}/api/v1/tests/${testId}`, authHeaders(token));
  },
  async getTestValues(token: string, testId: number) {
    return axios.get<IAnalyteValue[]>(`${apiUrl}/api/v1/tests/${testId}/values`, authHeaders(token));
  },
  async getTestResult(token: string, testId: number) {
    return axios.get<ITestResult>(`${apiUrl}/api/v1/test_results/${testId}`, authHeaders(token));
  },
  async getLatestTestResults(token: string, daysAgo: number = 1) {
    return axios.get<ITestResult[]>(`${apiUrl}/api/v1/test_results/latest?days=${daysAgo}`, authHeaders(token));
  },
  async getCommandSequence(token: string, assayId: number) {
    return axios.get<ICommandSequence>(`${apiUrl}/api/v1/assays/${assayId}/commands`, authHeaders(token));
  },

  async getTests(token: string, params: {
    page: number, perPage: number, sortBy: string, sortDesc: boolean, filters: IFilter[],
  }) {
    const paramsArray: string[] = [];
    if (params.page && params.perPage) {
      paramsArray.push(`skip=${(params.page - 1) * params.perPage}&limit=${params.perPage}`);
    }
    if (params.sortBy) {
      paramsArray.push(`sort=${params.sortBy}`);
    }
    if (params.sortDesc !== undefined) {
      paramsArray.push(`sort_desc=${params.sortDesc}`);
    }
    /*if (params.filters.length > 0) {
      for (const filter of params.filters) {
        paramsArray.push(`f=${JSON.stringify(filter)}`)
      }
    }*/
    return axios.post<ITestResult[]>(`${apiUrl}/api/v1/test_results/search?${paramsArray.join('&')}`,
      params.filters, authHeaders(token));
  },
  async getTestsCount(token: string, filters: IFilter[] = []) {
    const paramsArray: string[] = [];
    // console.log(filters);
    if (filters.length > 0) {
      for (const filter of filters) {
        paramsArray.push(`f=${JSON.stringify(filter)}`);
      }
    }
    // ?${paramsArray.join('&')}
    return axios.post<number>(`${apiUrl}/api/v1/test_results/stats`, filters, authHeaders(token));
  },

  async getSystems(token: string) {
    return axios.get<ISystem[]>(`${apiUrl}/api/v1/systems/`, authHeaders(token));
  },
  async updateSystem(token: string, systemId: number, data: ISystemUpdate) {
    return axios.put(`${apiUrl}/api/v1/systems/${systemId}`, data, authHeaders(token));
  },
  async getReleases(token: string) {
    return axios.get<IRelease[]>(`${apiUrl}/api/v1/releases/`, authHeaders(token));
  },
  async updateRelease(token: string, releaseId: number, data: IReleaseUpdate) {
    return axios.put(`${apiUrl}/api/v1/releases/${releaseId}`, data, authHeaders(token));
  },
  async createRelease(token: string, data: IReleaseCreate) {
    return axios.post(`${apiUrl}/api/v1/releases/`, data, authHeaders(token));
  },
  async getTestSummary(token: string, payload: {
    tests: number[], format: string, analytes: object[],
    readings: object[], groupBy: string,
    extraData?: object[], extraAnalytes?: object,
  }) {
    // console.log(payload);
    const tests = payload.tests.join('&t=');
    const { headers } = authHeaders(token);
    let responseFormat;

    const data = {
       analytes: payload.analytes,
       readings: payload.readings,
       extra_data: payload.extraData || [],
       extra_analytes: payload.extraAnalytes || [],
    };



    if (payload.format === 'EXCEL') {
      responseFormat = 'blob';
    }
    const responseType = responseFormat || 'text';
    return axios.post(`${apiUrl}/api/v1/test_results/summary?out_format=${payload.format}&t=${tests}&group_by=${payload.groupBy}`, data,
      {responseType, headers},
    );
  },
  async getTraces(token: string, data: number[], kind: string) {
    const tests = data.join('&t=');
    /*const analytes = analyteNames.join('&an=');
    let anString = '';
    if (analytes.length) {
      anString = `&an=${analytes}`;
    }*/
    const { headers } = authHeaders(token);

    return axios.get<ITrace[]>(`${apiUrl}/api/v1/tests/traces?t=${tests}&kind=${kind}`,
      {headers},
    );
  },
  async getAnalytes(token: string, assayIds: number[]) {
    const assays = assayIds.join('&a=');
    return axios.get<IAnalyte[]>(`${apiUrl}/api/v1/assays/analytes?a=${assays}`, authHeaders(token));
  },
  async getAssays(token: string, data: number[]) {
    const assays = data.join('&a=');
    return axios.get<IAssay[]>(`${apiUrl}/api/v1/assays/?a=${assays}`, authHeaders(token));
  },

  async getFields(token: string, payload: {field: string, skip: number, limit: number, filter: string}) {
    let fString = '';
    if (payload.filter) {
      fString = `&f=${payload.filter}`;
    }
    return axios.get<IAutocompleteItem[]>(`${apiUrl}/api/v1/test_results/field/${payload.field}?skip=${payload.skip}&limit=${payload.limit}${fString}`, authHeaders(token));
  },
  async getLogsForSystem(token: string, systemId: number) {
    return axios.get<IFile[]>(`${apiUrl}/api/v1/systems/${systemId}/logs`, authHeaders(token));
  },
  async getTestsForSystem(token: string, systemId: number) {
    return axios.get<ITestResult[]>(`${apiUrl}/api/v1/systems/${systemId}/tests`, authHeaders(token));
  },
  async getFile(token: string, fileId: number) {
    return axios.get<string>(`${apiUrl}/api/v1/files/${fileId}/read`, authHeaders(token));
  },
  async downloadFile(token: string, fileId: number) {
    const { headers } = authHeaders(token);
    const responseType = 'blob';
    return axios.get<Blob>(`${apiUrl}/api/v1/files/${fileId}/download`, {responseType, headers});
  },
  async getArchiveFiles(token: string, params?: {
    page: number, perPage: number,
  }) {
    return axios.get<IFile[]>(`${apiUrl}/api/v1/files/archives`, authHeaders(token));
  },
  async makeArchive(token: string, testIds: number[]) {
    const tests = testIds.join('&t=');
    return axios.get<string>(`${apiUrl}/api/v1/test_results/make_archive?t=${tests}`, authHeaders(token));
  },
};
