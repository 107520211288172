import { ITestResult, ITrace, IAssay } from '@/interfaces';
import { TestState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setTests(state: TestState, payload: ITestResult[]) {
        state.tests = payload;
    },
    setTest(state: TestState, payload: ITestResult) {
        const tests = state.tests.filter((test: ITestResult) => test.id !== payload.id);
        tests.push(payload);
        state.tests = tests;
    },
    setTestsCount(state: TestState, payload: number) {
        state.tests_count = payload;
    },

    setTestsOptions(state: TestState, payload:
        {page: number, itemsPerPage: number, sortBy: string, sortDesc: boolean}) {
        state.page = payload.page;
        state.perPage = payload.itemsPerPage;
        state.sortBy = payload.sortBy;
        state.sortDesc = payload.sortDesc;
    },
    setTestsPage(state: TestState, payload: number) {
        state.page = payload;
    },
    setTestsPerPage(state: TestState, payload: number) {
        state.perPage = payload;
    },
    setTestsSortBy(state: TestState, payload: string) {
        state.sortBy = payload;
    },
    setTestsSortDesc(state: TestState, payload: boolean) {
        state.sortDesc = payload;
    },

    setTestSummary(state: TestState, payload: string) {
        state.testSummary = payload;
    },

    setTraces(state: TestState, payload: ITrace[]) {
        state.traces = payload;
    },

    setAssays(state: TestState, payload: IAssay[]) {
        state.assays = payload;
    },
    setAssay(state: TestState, payload: IAssay) {
        const assays = state.assays.filter((assay: IAssay) => assay.id !== payload.id);
        assays.push(payload);
        state.assays = assays;
    },

    setArchiveTask(state: TestState, payload: {taskId: string, testIds: number[]}) {
        state.archiveTasks[payload.taskId] = payload.testIds;
    },
    removeArchiveTask(state: TestState, payload: string) {
        delete state.archiveTasks[payload];
    },
};

const { commit } = getStoreAccessors<TestState, State>('');

export const commitSetTest = commit(mutations.setTest);
export const commitSetTests = commit(mutations.setTests);
export const commitSetTestsCount = commit(mutations.setTestsCount);
export const commitSetTestsPage = commit(mutations.setTestsPage);
export const commitSetTestsPerPage = commit(mutations.setTestsPerPage);
export const commitSetTestsSortBy = commit(mutations.setTestsSortBy);
export const commitSetTestsSortDesc = commit(mutations.setTestsSortDesc);
export const commitSetTestsOptions = commit(mutations.setTestsOptions);

export const commitSetTestSummary = commit(mutations.setTestSummary);
export const commitSetTraces = commit(mutations.setTraces);

export const commitSetAssay = commit(mutations.setAssay);
export const commitSetAssays = commit(mutations.setAssays);


export const commitSetArchiveTask = commit(mutations.setArchiveTask);
export const commitRemoveArchiveTask = commit(mutations.removeArchiveTask);
