import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

const opts = {};

/*{
  iconfont: 'md',
};*/

export default new Vuetify(opts);
