import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

import gxdbRouter from './routers/gxdb';
import ottoRouter from './routers/otto';

const name = process.env.VUE_APP_NAME;

let router: Router;

if (name === 'GeneXpert+') {
  router = gxdbRouter;
} else if (name === 'Xpert Insight BC') {
  router = ottoRouter;
} else {
  router = gxdbRouter;
}

export default router;
